:root {
  /* root font size that REMs are based on */
  font-size: 16px;

  /* main colors */
  --new-primary-color: #40d0be;
  --new-secondary-color: #2f61f4;
  --primary-color: #1b79de;
  --primary-color-light: #598bff;
  --primary-color-lighter: #d0d7e7;
  --primary-bg-color: #fafafa;
  --secondary-color: #269672;
  --secondary-bg-color: #fff;
  --selected-color: #f8bd49;
  --warning-color: #ffd800;
  --warning-color-2: #e69d18;
  --danger-color: #b32626;
  --negative-color: #f28b8f;
  --positive-color: #00ac56;
  --neutral-color: #777;
  --new-tertiary-color: #376976;

  /* fonts */
  --header-font: "Ubuntu", sans-serif;
  --text-font: "Ubuntu", sans-serif;

  /* buttons */
  --primary-button-bg-color: var(--primary-color);
  --primary-button-text-color: #fff;
  --primary-button-text-color-without-bg: #777;
  --primary-button-bg-color-hover: var(--primary-color);
  --primary-button-text-color-hover: #fff;
  --primary-button-border-radius: 40px;
  --primary-button-gradient: linear-gradient(to right, #034ffc, #329fc3);
  --primary-button-gradient-hover: linear-gradient(to right, #3471ff, #55b4d3);
  --primary-button-padding: 0.8rem 1.1rem;
  --primary-button-bg-color-current: #7ddbd0;
  --primary-button-bg-color-current-hover: #7ddbd0;
  --primary-button-bg-color-active: #7bd9cedc;
  --secondary-button-bg-color: #f9f9f9;
  --secondary-button-border-radius: var(--primary-button-border-radius);
  --secondary-button-padding: var(--primary-button-padding);
  --secondary-button-text-color: #464646;
  --secondary-button-bg-color-hover: #fff;
  --secondary-button-text-color-hover: #646464;
  --secondary-button-bg-color-disabled: #c0c0c0;
  --secondary-button-text-color-disabled: #646464;
  --secondary-button-bg-color-active: #f9f9f9d5;
  --tertiary-button-bg-color: #fff;
  --tertiary-button-border-radius: var(--primary-button-border-radius);
  --tertiary-button-padding: var(--primary-button-padding);
  --tertiary-button-text-color: #464646;
  --tertiary-button-bg-color-hover: #e7e7e7;
  --tertiary-button-text-color-hover: #646464;
  --tertiary-button-bg-color-disabled: #c0c0c0;
  --tertiary-button-text-color-disabled: #646464;
  --tertiary-button-bg-color-active: #f1f1f1;
  --material-grey-btn-color: #7a8ea2;
  --material-grey-btn-color-hover: #fff;
  --material-grey-btn-bg-color: #eff4f6;
  --material-grey-btn-bg-color-active: #c7ccce;
  --button-disabled-bg-color: #b3b3b3;
  --button-disabled-text-color: #dbdbdb;
  --help-button-disabled-bg-color: #d4d4d4;
  --help-button-disabled-icon-color: #bbbbbb;
  --filter-button-bg: #e7e7e7;
  --filter-button-text-color: #585858;

  --delete-button-bg-color: #d64646;
  --delete-button-bg-color-hover: #db6b6b;
  --delete-button-text-color: #fff;
  --delete-button-bg-color-active: #eb8082;
  --cancel-button-bg-color: #afafaf;
  --cancel-button-bg-color-hover: #bbbbbb;
  --cancel-button-text-color: #fff;
  --cancel-button-bg-color-active: #ececec;

  /* pagination */
  --pagination-btn-bg-color: #fff;
  --pagination-btn-border: 1px solid #ccc;
  --pagination-btn-border-radius: 20px;
  --pagination-btn-text-color: #a4a5aa;
  --pagination-btn-text-color-active: #fff;
  --pagination-btn-text-color-hover: #fff;
  --pagination-btn-bg-color-active: var(--primary-color);
  --pagination-btn-bg-color-hover: var(--primary-color);
  --disabled-pagination-btn-text-color: #98aab8;
  --disabled-pagination-btn-bg-color: #eff4f6;

  /* text */
  --primary-font-color: #111b32;
  --primary-font-color-light: #616161;
  --secondary-font-color: #fff;
  --tertiary-font-color: #afafaf;

  /* sidebar */
  --sidebar-width-expanded: 16rem;
  --sidebar-width-contracted: 4.7rem;
  --sidebar-anim-duration: 0.3s;
  --sidebar-link-text-color: #bbc0ca;
  --sidebar-link-padding-expanded: 24px 20px 20px 38px;
  --sidebar-link-padding-contracted: 23px 23px 23px 26px;
  --sidebar-link-hover-background-color: #f3f3f3;
  --sidebar-link-active-background-color: #e7e7e7;
  --nav-dropdown-bg-color-hover: #f5f5f5;
  --nav-dropdown-bg-color-active: #fff;

  /* savebar */
  --savebar-height: 4.5rem;
  --savebar-bg-color: #fff;
  --savebar-padding: 0.7rem var(--content-side-padding);
  --savebar-height-space-allowance: calc(var(--savebar-height) + 2rem);
  --disabled-savebar-btn-bg-color: rgb(117, 117, 117);
  --disabled-savebar-btn-font-color: rgb(143, 143, 143);
  --disabled-savebar-btn-nobg-font-color: #ccc;

  /* breadcrumbs */
  --breadcrumbs-height: 4rem;
  --breadcrumbs-vertical-padding: 0rem;
  --breadcrumbs-vertical-padding-total: calc(
    var(--breadcrumbs-vertical-padding) * 2
  );
  --breadcrumbs-padding: var(--breadcrumbs-vertical-padding)
    var(--content-side-padding);
  --breadcrumbs-calculated-height: calc(
    var(--breadcrumbs-height) + var(--breadcrumbs-vertical-padding-total)
  );
  --breadcrumbs-bg-color: var(--primary-bg-color);

  /* main content */
  --content-side-padding: 2rem;

  /* borders */
  --primary-border-width: 1px;
  --primary-border-color: #ebebeb;
  --primary-border: var(--primary-border-width) solid
    var(--primary-border-color);
  --primary-item-border-color: #9e9e9e;
  --primary-item-border: var(--primary-border-width) solid
    var(--primary-item-border-color);
  --secondary-border-width: 0.15rem;
  --secondary-border-color: #d7d7d7;
  --secondary-border: var(--secondary-border-width) solid
    var(--secondary-border-color);
  --input-border-color: #ededed;

  /* header nav */
  --nav-bar-height: 4rem;
  --nav-logo-height: 1.5rem;
  --nav-button-hover-bg-color: #e9e9e9;
  --nav-button-active-bg-color: #e4e4e4;
  --nav-button-icon-content-active-bg-color: var(--primary-color);
  --nav-button-icon-hover-bg-color: #fff;

  /* tab nav */
  --tab-bg-color: var(--primary-bg-color);
  --tab-text-color: #b2b2b2;
  --tab-active-click-bg-color: #fbfbfb;
  --tab-active-click-text-color: var(--primary-font-color);
  --tab-active-bg-color: #fff;
  --tab-active-text-color: #0c0c0c;
  --tab-hover-bg-color: #fff;
  --tab-disabled-bg-color: var(--primary-bg-color);
  --tab-disabled-text-color: #ccc;

  /* help content */
  --help-width: 25rem;
  --help-bg-color: #fff;

  /** generic */
  --success: #41cc2f;

  /* flash messages */
  --flash-message-width: 40rem;
  --flash-message-top: calc(var(--nav-bar-height) + 2rem);
  --flash-message-right: 2rem;
  --flash-message-color-default: var(--primary-color);
  --flash-message-color-success: #b3e7ac;
  --flash-message-color-warning: #f4e782;
  --flash-message-color-error: #e07c65;
  --flash-message-text-color: #68738b;
  --flash-message-font-size: 0.9rem;
  --flash-message-text-color-default: var(--primary-font-color);

  /* Alert messages */
  --alert-message-bg-color-warning: #00000000;
  --alert-message-bg-color-success: var(--primary-color);
  --alert-message-bg-color-error: #f17d82;
  --alert-message-text-color-warning: #b14a4a;
  --alert-message-text-color-error: #fff;
  --alert-message-font-size: 0.9rem;

  /* shadows */
  --material-shadow-color: #ccc;
  --material-shadow-blur: 0.75rem;
  --material-shadow-amount: 0.05rem;
  --material-shadow-bottom: 0 var(--material-shadow-amount)
    var(--material-shadow-blur) var(--material-shadow-color);
  --material-shadow-right: var(--material-shadow-amount) 0
    var(--material-shadow-blur) var(--material-shadow-color);
  --material-shadow-left: 0 var(--material-shadow-amount)
    var(--material-shadow-blur) var(--material-shadow-color);

  /* icons */
  --icon-width-illustration: 47rem;
  --icon-width-logo: 20rem;
  --icon-width-large: 10rem;
  --icon-width-medium: 5rem;
  --icon-width-small: 1.6rem;
  --icon-width-tiny: 1rem;
  --icon-width-navbar: 1.8rem;
  --icon-circular-bg-color: #f3f3f3;

  /* content dimensions */
  --content-height: calc(100vh - var(--nav-bar-height));

  /* simulation list items */
  --simulation-item-title-bg-color: #fff;
  --high-avg-score-color: var(--primary-color);
  --mid-avg-score-color: var(--warning-color);
  --low-avg-score-color: #f17d82;

  /* cards */
  --card-border-radius: 24px;
  --card-bg-color: #fff;
  --card-box-shadow-color: #eaeaea;
  --card-box-shadow: 0 3px 7px 1px var(--card-box-shadow-color);

  /* modal */
  --modal-bg-color: #ebebeb;

  /* links */
  --link-text-color: var(--primary-color);
  --link-text-color-hover: #5aa9fd;
  --link-text-color-active: #aad0f8;
  --link-text-color-visited: var(--primary-color);
  --link-text-color-light: #b2b2b2;
  --link-text-color-light-hover: #bbbbbb;
  --link-text-color-light-active: #c5c5c5;
  --link-text-color-light-visited: var(--link-text-color-light);
  --caps-link-text-color: #d0d0d0;
  --caps-link-text-color-hover: var(--primary-color);
  --caps-link-text-color-active: var(--primary-color);
  --caps-link-text-color-visited: #d0d0d0;
  --image-link-opacity-hover: 0.8;
  --image-link-opacity-active: 0.7;

  /* forms */
  --validation-error-text-color: #e15040;
  --validation-error-input-border-color: #e15040;
  --text-input-bg-color: #f7f9fb;
  --text-input-text-color: var(--primary-font-color);
  --text-input-placeholder-text-color: #ccc;
  --input-alt-bg-color: #fff;
  --input-alt-border: 1px solid #e3e6eb;
  --input-alt-placeholder-text-color: #ccc;
  --input-alt-border-radius: 8px;
  --white-input-placeholder-color: #ccc;

  --checkbox-bg-color: #efefef;
  --checkbox-bg-color-grey-form: #fff;
  --checkbox-bg-color-hover: var(--primary-color-light);
  --checked-checkbox-bg-color: var(--primary-color);
  --checkbox-tick-color: #fff;
  --form-section-bg-color: #ebebeb;
  --input-focus-color: var(--primary-button-bg-color-hover);
  --disabled-checkbox-color: #ccc;
  --disabled-checkbox-text-color: #969696;
  --disabled-form-input-bg-color: #d2d2d2;
  --form-max-width: 50rem;
  --form-colored-btn-hover-bg-color: #ccc;
  --input-height: 3.1rem;
  --input-border: 1px solid #e9edf1;
  --input-bg-color: #f7f9fb;
  --input-bg-color-hover: #fff;
  --alt-form-bg-color: #f7f9fb;
  --input-border-color: #e9edf1;
  --input-border-radius: 4px;
  --char-counter-width: 2rem;
  --char-counter-input-margin-right: calc(var(--char-counter-width) + 0.3rem);
  --range-input-text-width: 3rem;
  --range-input-margin-right: calc(var(--range-input-text-width) + 0.3rem);

  --char-counter-okay-text-color: green;
  --char-counter-caution-text-color: orange;
  --char-counter-max-text-color: red;
}
